<template>
  <div>
    <div class="p-grid crud-demo">
      <div class="p-col-12">
        <div class="card layout-toppanel">
          <p>
            <i class="pi pi-fw pi-info-circle"></i> Edite el item de T.U.P.A,
            deben registrarse correctamente siguiendo las pautas de casillas
            obligatorias representadas por (*)
          </p>
        </div>
        <div class="card">
          <Toast />
          <Toolbar class="p-mb-4">
            <template v-slot:left>
              <Button
                :disabled="isBussy"
                label="Guardar"
                icon="pi pi-check"
                class="p-button-info"
                @click="guardar"
            /></template>
            <template v-slot:right>
              <Button
                label="Salir"
                icon="pi pi-arrow-left"
                class="p-button-danger"
                @click="open('list')"
              />
            </template>
          </Toolbar>
          <div class="p-grid p-fluid dashboard">
            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Dependencia o sede (*)</h5>
              <Dropdown
                v-model="product.ejecutora"
                required="true"
                :options="ejecutora"
                :filter="true"
                optionLabel="nombre"
                :disabled="isBussy"
                placeholder="Seleccione la sede o dependencia"
                :class="{ 'p-invalid': submitted && !product.ejecutora }"
                filterPlaceholder="Busque la Ejecutora"
              />
              <small class="p-invalid" v-if="submitted && !product.ejecutora"
                >Seleccione la dependencia o sede.</small
              >
            </div>
          </div>
          <div class="p-grid p-fluid dashboard">
            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Denominación: (*)</h5>
              <InputText
                v-model.trim="product.nombre"
                required="true"
                placeholder="Denominación completa"
                autofocus
                :class="{ 'p-invalid': submitted && !product.nombre }"
              />
              <small class="p-invalid" v-if="submitted && !product.nombre"
                >Ingrese nombre o denominación.</small
              >
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Detalle en PDF (*)</h5>
              <FileUpload
                mode="basic"
                :customUpload="true"
                @uploader="myUploader"
                :fileLimit="1"
                :auto="true"
              />
              <label>Archivo: {{ filetitulo }} {{ filetype }}</label>
              <small class="p-invalid" v-if="submitted && !filetitulo">
                Archivo es requerido.</small
              >
            </div>

            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Observación/Objeto</h5>
              <Textarea
                v-model="product.descripcion"
                required="true"
                rows="3"
                cols="20"
              />
            </div>
            <div class="p-col-3 p-md-3 p-lg-3">
              <h5>Nro. de Orden</h5>
              <InputNumber
                v-model="product.orden"
                showButtons
                buttonLayout="horizontal"
                decrementButtonClass="p-button-warning"
                incrementButtonClass="p-button-info"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Tupa Pago:</h5>
              <label class="form-label" style="margin-bottom: 0px"
                >¿Se mostrará en el formulario de solicitud de pago al
                ciudadano?:
              </label>
              <Checkbox name="agente" v-model="product.cTramite" value="si" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  data() {
    this.action = "gstupa/TUPA_UPDATE";
    return {
      isBussy: false,
      submitted: false,
      cTramite : false,
      product: {},
      filetitulo: "",
      filetype: "",
      ejecutora: null,
      products: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nombre: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
    };
  },
  methods: {
    guardar() {
      this.submitted = true;
      if (
        this.product.nombre &&
        this.product.descripcion &&
        this.product.ejecutora
      ) {
        let modelo = {
          id: this.product.id,
          ejecutora: this.product.ejecutora,
          orden: this.product.orden,
          nombre: this.product.nombre,
          descripcion:
            this.product.descripcion === undefined
              ? ""
              : this.product.descripcion,
          docuRuta:
            this.product.docuRuta === undefined ? "" : this.product.docuRuta,
          docuTipo:
            this.product.docuTipo === undefined ? "" : this.product.docuTipo,

           cTramite: this.cTramite.length  > 0 ? true : false
        };

        this.$swal({
          width: 370,
          text: "Cargando...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch(this.action, modelo)
              .then((res) => {
                this.$swal.close();
                this.open("list");
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Item guardada con id: " + res.id,
                  life: 3000,
                });
              })
              .catch((error) => {
                this.$swal.close();
                this.error(error);
              });
          },
        }).then((result) => {
          console.log(result);
        });
      }
    },

    myUploader(file) {
      this.$swal({
        width: 370,
        text: "Subiendo archivo esperer porfavor...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/FILE_UPLOAD", file)
            .then((res) => {
              this.$swal.close();
              this.filetitulo = res.titulo;
              this.filetype = "[" + file.files[0].type + "]";
              this.product.docuTipo = file.files[0].type;
              this.product.docuRuta = res.destino;
            })
            .catch((error) => {
              this.$swal.close();
              this.fileTitulo = "error... " + error;
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    formatCurrency(value) {
      if (value.length >= 150) {
        return value.substring(0, 130) + "...";
      } else {
        return value;
      }
    },
    listarEjecutora(e) {
      this.isBussy = true;
      this.$store
        .dispatch("gstupa/DEPENDENCIA_LIST", e === undefined ? true : e)
        .then((res) => {
          this.isBussy = false;
          this.ejecutora = res;

          let eject = this.product.ejecutora;
          let seject = null;
          res.forEach(function (element) {
            if (eject.idEjecutora == element.idEjecutora) {
              seject = element;
            }
          });
          this.product.ejecutora = seject;
        })
        .catch((error) => {
          this.isBussy = false;
          this.error(error);
        });
    },

    open(operacion) {
      let mutacion = {
        oper: operacion,
        tupa: {
          obra: {
            nombre: "",
          },
        },
      };

      this.$router.push({
        name: "tupa/amigable",
      });
      this.$store.commit("gstupa/OPERACION_TUPA", mutacion);
    },
    error(evt) {
      if (evt.response.status == 401 || evt.response.status == 500) {
        this.$swal(
          evt.response.data.errorMessage,
          "Ud. no tiene permitido esta función",
          "warning"
        );
      }
    },
  },

  mounted() {
    if (this.tupa.estado == false) {
      this.$router.push({
        name: "tupa/amigable",
      });
    }

    this.listarEjecutora();
    this.product = this.tupa;

    this.filetitulo = this.tupa.docuExtension;
    this.filetype = this.tupa.docuTipo;
    this.cTramite = this.tupa.cTramite ? true : ""
  },

  computed: {
    ...mapGetters({ tupa: "gstupa/getModelTupa" }),
  },
};
</script>
<style>
</style>